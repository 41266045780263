<template>
    <LandPanel :title="data.title" :tabs="data.tabs" v-model="data.activeTabName" :moreClick="moreClick" :showMore="data.showMore">
        <template v-slot:tab-zr>
            <el-empty description="暂无数据" v-if="data.landList_ZR.length === 0"></el-empty>
            <el-row :gutter="12" v-if="data.landList_ZR.length > 0">
                <el-col :span="6" v-for="item in data.landList_ZR" :key="item.id">
                    <LandItemCard :landInfo="item"   />
                </el-col>
            </el-row>
          <div class="user-info-list-page" v-if="!data.showMore">
            <el-pagination background style="float: right" layout="total,  prev, pager, next, jumper" :page-sizes="[16]"  :page-count="datalist.totalPage" :current-page="datalist.current" @current-change="pageChange">
            </el-pagination>
          </div>
        </template>
        <template v-slot:tab-cz>
            <el-empty description="暂无数据" v-if="data.landList_CZ.length === 0"></el-empty>
            <el-row :gutter="12" v-if="data.landList_CZ.length > 0">
                <el-col :span="6" v-for="item in data.landList_CZ" :key="item.id">
                    <LandItemCard :landInfo="item" />
                </el-col>
            </el-row>
        </template>
        <template v-slot:tab-cr>
            <el-empty description="暂无数据" v-if="data.landList_CR.length === 0"></el-empty>
            <el-row :gutter="12" v-if="data.landList_CR.length > 0">
                <el-col :span="6" v-for="item in data.landList_CR" :key="item.id">
                    <LandItemCard :landInfo="item"   />
                </el-col>
            </el-row>
        </template>
    </LandPanel>

</template>
<script setup>
import { onMounted, reactive,defineEmits } from 'vue'
import CommonService from '@/service/CommonService';
import router from "../router/index";

const data = reactive({
  title:"转让信息",
  showMore:true,
    activeTabName:"tab-zr",
  tabName:'转让',
    tabs:[
        {
            name: "tab-zr",
            label: "",
        }
    ],
    landList_ZR: [],
    landList_CZ: [],
    landList_CR: [],

})

const pageChange = (page) =>{
  datalist.current=page;
  loadLandZR(datalist);
}


const datalist = reactive({
  landSupplyType: 2,
  size:8,
  current:1,
  totalPage:1,
    sy:1,
    tableData: [

    ]
})


const moreClick = () =>{
   // if(data.activeTabName === "tab-zr"){
   //     router.push("supply")
  //  }else if(data.activeTabName === "tab-cr"){

   //   router.push("stock")
  //  }
  data.showMore=false;
  datalist.size=16;
  pageChange(1);
}

const loadLandZR = () => {

    //获取土地列表
    CommonService.landSearch(datalist).then(res => {
        if (res.code === 0) {
          data.landList_ZR = res.data.records;
         let numbe=res.data.total/datalist.size;
          if(numbe-parseInt(numbe)>0)
          {
            datalist.totalPage=parseInt(numbe)+1
          }else {
            if(numbe==0)
            {
              numbe++;
            }
            datalist.totalPage=parseInt(numbe);
          }
        }
    });
}

const loadLandCZ = () => {
    var params = {
        landSupplyType: 0,
        current:1,
        size:8
    }

    //获取土地列表
    CommonService.landSearch(params).then(res => {
        if (res.code === 0) {
            data.landList_CZ = res.data.records;
        }
    });
}

const loadLandCR = () => {
    var params = {
        landSupplyType: 2,
        current:1,
        size:8
    }

    //获取土地列表
    CommonService.landSearch(params).then(res => {
        if (res.code === 0) {
            data.landList_CR = res.data.records;
        }
    });
}

onMounted(() => {
    loadLandZR();
    //loadLandCZ();
    //loadLandCR();
})
</script>

<style lang="scss" >

.el-icon-arrow-right:before {
  content: '';//右边“下一页”三角箭头
}
.el-icon-arrow-left:before {
  content: '';//左边“上一页”三角剪头
}
.el-pager li {
  min-width: 24px;//一定要加！不然默认的宽度是32px
  width: 24px;
  height: 28px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  border-radius: 4px;
  color: #626469; //字体颜色
  background: #ffffff; //背景色
  line-height: 28px;
  &.active { //选中时字体颜色、背景色变化
    color: #fb4a3e;
    background: #0960bd center center no-repeat;
  }
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0960bd;

}
</style>