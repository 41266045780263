<template>
    <LandPanel title="求租信息" :tabs="data.tabs" v-model="data.activeTabName" :moreClick="moreClick" :showMore="data.showMore">
        <template v-slot:tab-qg>
            <el-empty description="暂无数据" v-if="data.landList_qg.length === 0"></el-empty>
            <el-row :gutter="16" v-if="data.landList_qg.length > 0">
                <el-col :span="8" v-for="item in data.landList_qg" :key="item.id">
                    <DemandCard :item="item" />
                </el-col>
            </el-row>

        </template>
        <template v-slot:tab-qz>
            <el-empty description="暂无数据" v-if="data.landList_qz.length === 0"></el-empty>
            <el-row :gutter="16" v-if="data.landList_qz.length > 0">
                <el-col :span="8" v-for="item in data.landList_qz" :key="item.id">
                    <DemandCard :item="item" type="rent"/>
                </el-col>
            </el-row>
          <div class="user-info-list-page" v-if="!data.showMore">
            <el-pagination background  style="float: right" layout="total,  prev, pager, next, jumper" :page-sizes="[18]" :page-count="datalist.totalPage" :current-page="datalist.current" @current-change="pageChange">
            </el-pagination>
          </div>
        </template>
    </LandPanel>
</template>
<script setup>
import { onMounted, reactive,defineEmits } from 'vue'
import CommonService, {getRentList} from '@/service/CommonService';
import router from "../router/index";
import DemandCard from '@/components/DemandCard'

const data = reactive({
    activeTabName:"tab-qz",
  showMore:true,
    tabs:[

        {
            name: "tab-qz",
            label: "",
        },
    ],
    landList_qg: [],
    landList_qz: [],
})
const datalist = reactive({
  demandType: 2,
  size:9,
  current:1,
  auditStatus:2,
  totalPage:1,
  tableData: [

  ]
})
const moreClick = () =>{
    // if(data.activeTabName === "tab-zr"){
    //     router.push("supply")
    // }else if(data.activeTabName === "tab-cr"){
    //     router.push("stock")
    // }
    //router.push("demand");
  data.showMore=false;
  datalist.size=18;
  pageChange(1);
}
const pageChange = (page) =>{
  datalist.current=page;
  loadLandQZ(datalist);
}

const loadLandQG = () => {
    var params = {
        current:1,
        demandType:1,
        auditStatus:2,
        size:9
    }

    //获取土地列表
    CommonService.getDemandLandList(params).then(res => {
        data.landList_qg = res.data.records
    })
}

const loadLandQZ = () => {
    //获取土地列表
    CommonService.getRentList(datalist).then(res => {

      if (res.code === 0) {
        data.landList_qz = res.data.records
        let numbe=res.data.total/datalist.size;

        if(numbe-parseInt(numbe)>0)
        {
          datalist.totalPage=parseInt(numbe)+1
        }else {
          if(numbe==0)
          {
            numbe++;
          }
          datalist.totalPage=parseInt(numbe);
        }
      }
    })
}

onMounted(() => {
   // loadLandQG();
    //loadLandCZ();
    loadLandQZ();
})
</script>

<style lang="scss" >

.el-icon-arrow-right:before {
  content: '';//右边“下一页”三角箭头
}
.el-icon-arrow-left:before {
  content: '';//左边“上一页”三角剪头
}
.el-pager li {
  min-width: 24px;//一定要加！不然默认的宽度是32px
  width: 24px;
  height: 28px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  border-radius: 4px;
  color: #626469; //字体颜色
  background: #ffffff; //背景色
  line-height: 28px;
  &.active { //选中时字体颜色、背景色变化
    color: #fb4a3e;
    background: #0960bd center center no-repeat;
  }
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0960bd;

}
</style>