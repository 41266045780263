import { render, staticRenderFns } from "./OperationVideo.vue?vue&type=template&id=484a526f&"
import script from "./OperationVideo.vue?vue&type=script&setup=true&name=needAdd&lang=js&"
export * from "./OperationVideo.vue?vue&type=script&setup=true&name=needAdd&lang=js&"
import style0 from "./OperationVideo.vue?vue&type=style&index=0&id=484a526f&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports