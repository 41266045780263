<template>
    <el-card :body-style="{ padding: '0px' }" shadow="hover" class="land-demand-card">
        <div class="card-content" style="padding: 14px">
            <div class="card-header">
                <h3>{{ item.title }}</h3>
                <el-button type="primary" size="mini" @click="toDetail">需求详情</el-button>
            </div>
            <div class="item-addr">
                <span>
                    <i class="ti ti-map-pin"></i>
                    {{ getXzqName(item.district) }}
                </span>
                {{ item.addr }}
            </div>
            <el-divider></el-divider>
            <div class="item-price">
                土地价&nbsp;&nbsp;
                <span v-if="item.expectPriceLow || item.expectPriceHigh">
                    {{ item.expectPriceLow }} ~ {{ item.expectPriceHigh }}
                </span>
                <i  v-if="item.expectPriceLow || item.expectPriceHigh">&nbsp;万元</i>
                <span v-else>面议</span>
            </div>
            <div class="item-quantity">
                <div>
                    需求量&nbsp;&nbsp;
                    <span>
                        {{ item.landSizeMin }} ~ {{ item.landSizeMax }}
                    </span>
                    &nbsp;平方米
                </div>
                <div>
                    <!-- <el-button type="warning" size="medium">公共管理与公共服务</el-button>
                    <el-button type="warning" size="medium">VR地块</el-button> -->
                </div>
            </div>
        </div>
    </el-card>
</template>
<script>
const xzq = {
    'jinyang': '旌阳区',
    'jingkai': '市本级',
    'luojiang': '罗江区',
    'guanghan': '广汉市',
    'shifang': '什邡市',
    'mianzhu': '绵竹市',
    'zhongjiang': '中江县',
};

export default {
    name: "demandCard",
    data() {
        return {};
    },
    props: {
        type:{
          type:String,
          default:''
        },
        item: {
            type: Object,
            default: () => ({
                title: "XXXX商贸有限公司",
                district: "武侯区",
                addr: "XXXX街道XXXX号",
                priceRange: "1500~1700",
                uses: "商业用地",
                amount: "100万元",
                date: "2020-01-01 15:00:00",
            }),
        },
    },
    methods: {
        getXzqName(id) {
            return xzq[id];
        },
        toDetail() {
          let tmpPath = this.type === 'rent' ? '/rent' : 'demand' ;
            this.$router.push({
                path: tmpPath +"/detail/"+this.item.id,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.land-demand-card {
    margin-bottom: 20px;
    .card-header {
        display: flex;
        flex-direction: row;

        h3 {
            flex: 1;
        }
    }

    .el-divider--horizontal {
        margin: 12px 0px;
    }

    .item-addr {
        margin-top: 12px;

        span {
            color: #1e88fb;
        }
    }

    .item-price {
        span {
            color: #d6261c;
            font-size: 28px;
            font-weight: bold;
        }
    }

    .item-quantity {
        display: flex;
        flex-direction: row;
        margin-top: 12px;

        div:first-of-type {
            flex: 1;

            span {
                font-size: 18px;
            }
        }
    }
}</style>
