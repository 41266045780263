<template>
  <el-dialog custom-class="minWidthLandAdd" title="交易机构" :visible.sync="visible" :close-on-click-modal="false" @close="onClose" draggable>
    <div style="text-align: left">
      <div class="card-content" style="padding: 14px;    line-height: 38px;" @click="nogo(item.url)">
        <div class="card-header">
          <h2>{{ data.title }}</h2>

        </div>
        <div class="item-addr">
                <span style="font-size: 14px;color: #3f3f3f">
服务范围：{{ data.district }}
                </span>

        </div>
        <div class="item-addr" style="font-size: 14px;">
                <span>
联系单位：{{ data.addr }}
                </span>

        </div>
        <div class="item-addr" style="font-size: 14px">
                <span>
联系方式：{{ data.tel }}
                </span>

        </div>
        <div class="item-addr" style="font-size: 14px">
                <span>
联系地址：{{ data.address }}
                </span>

        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script setup  name="needAdd">
import { reactive, watch, ref, nextTick, computed, defineAsyncComponent } from 'vue'
const emit = defineEmits(['refresh']);
const mapDialogRef = ref();

const openMapDialog = () => {
  mapDialogRef.value.openDialog((data) => {
    console.log(data);
  });
};

// 定义变量内容
const dataFormRef = ref();
const visible = ref(false);
const loading = ref(false);

const data = reactive({
  title:10,
  district:1,
  addr:1,
  tel: '',
  address: '',
})

const onClose= () =>{

}

// 打开弹窗
const openDialog = (title) => {
  visible.value = true;
console.log(title)

  const datajosn=[
    {
      title: "市本级",
      district: "承担市中心城区范围内土地二级市场交易相关工作",
      addr: "德阳市土地矿权交易中心",
      address:"四川省德阳市凯江路二段109号 ",
      tel: "0838-2502185",
      url: "/service-guide?id=1666269628714201127",
    },{
      title: "旌阳区",
      district: "承担市中心城区范围外土地二级市场交易相关工作",
      addr: "德阳市旌阳区土地矿权交易所",
      address:"德阳市旌阳区洮河路8号",
      tel: "0838-6161329",
      url: "/service-guide?id=1666269628714201127",
    },{
      title: "罗江区",
      district: "承担罗江区事权范围内土地二级市场交易相关工作",
      addr: "罗江区不动产登记和土地储备中心",
      address:"四川省德阳市罗江区景乐南路377号",
      tel: "0838-3120500",
      url: "/service-guide?id=1666269628714201127",
    },{
      title: "绵竹市",
      district: "承担绵竹市事权范围内土地二级市场交易相关工作",
      addr: "绵竹市国有土地矿产资源使用权交易所",
      tel: "0838-6603030",
      address:"绵竹市城东新区自然资源和规划局",
      url: "/service-guide?id=1666269628714201127",
    },{
      title: "广汉市",
      district: "承担广汉市事权范围内土地二级市场交易相关工作",
      addr: "广汉市不动产登记中心",
      address:"广汉市雁江南路西四段3号",
      tel: "0838-5228342 ",
      url: "/service-guide?id=1666269628714201127",
    },{
      title: "什邡市",
      district: "承担什邡市事权范围内土地二级市场交易相关工作",
      addr: "什邡市不动产登记中心",
      address:"什邡市方亭街道蓥华山路南段61号",
      tel: "0838-8261661",
      url: "/service-guide?id=1666269628714201127",
    },{
      title: "中江县",
      district: "承担中江县事权范围内土地二级市场交易相关工作",
      addr: "中江县不动产登记中心",
      address:"中江县凯江镇一环路东段618号",
      tel: "0838-2502184",
      url: "/service-guide?id=1666269628714201127",
    }
  ]
  for (let i=0;i<datajosn.length;i++){
    if(datajosn[i].title==title)
    {
      data.title=datajosn[i].title;
      data.district=datajosn[i].district;
      data.addr=datajosn[i].addr;
      data.address=datajosn[i].address;
      data.tel=datajosn[i].tel;

    }
  }
  // 获取landInfo信息
};




const forms = reactive({
  timer:null,
  userverify:false
})

// 暴露变量
defineExpose({
  openDialog,
  stop
});
</script>

<style>
.minWidthLandAdd{
  min-width: 1260px !important;
}

</style>

<style lang="scss" scoped>
.land-demand-card {
  margin-bottom: 20px;
  .card-header {
    display: flex;
    flex-direction: row;

    h3 {
      flex: 1;
    }
  }

  .el-divider--horizontal {
    margin: 12px 0px;
  }

  .item-addr {
    margin-top: 6px;

    span {
      color: #1e88fb;
    }
  }

  .item-price {
    span {
      color: #d6261c;
      font-size: 28px;
      font-weight: bold;
    }
  }

  .item-quantity {
    display: flex;
    flex-direction: row;
    margin-top: 6px;

    div:first-of-type {
      flex: 1;

      span {
        font-size: 18px;
      }
    }
  }
}</style>
